<p-toast position="top-right"></p-toast>

<div class="col-12" *ngIf="requestNumber.length > 0">
  <div class="grid p-justify-between">
    <div class="col-10 div-request-header">
      <h5 style="text-transform: unset !important;"> {{requestTitle}} </h5>
    </div>
    <div class="col-2 div-request-header">
      <button pButton type="button" style="float:right;" class="p-button-success" pButton icon="pi pi-copy" label="Copy"
              (click)="showCopyRequestPopUp()" *ngIf="isRequester">
      </button>
    </div>
  </div>
  <app-copy-request [visible]="showCopyRequest" [requestNumber]="requestNumber" [requestType]="requestType" [copyRequester]="copyRequester"
                    (eventToClosePopUp)="closeCopyRequestPopUp($event)"></app-copy-request>
</div>


<div class="resShadow request">
  <div class="ui-g">
    <!-- show chemical detail -->
    <app-request-detail [requestNumber]="requestNumber" [requestType]="requestType" class="width-100-percent"
                        (eventToReturnRequestModelToParentComponent)="getRequestModel($event)" *ngIf="requestNumber.length > 0">
    </app-request-detail>

    <!-- Stepper Section -->
    <div class="col-12">
      <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)">

        <mat-step [stepControl]="requesterDetailsGroup" [editable]="isStepsEditable">
          <ng-template matStepLabel>Requester Details </ng-template>
          <!-- This component is Requester Details section -->
          <add-requester-address [requesterInformationModel]="requesterInformationModel"
           [requestModel]="requestModel"  
           (returnRequesterInformationModel)="getUpdatedRequestInformationModel($event)"
           *ngIf="requestModel.RequestId > 0&&requestType==='P2P'" >
          </add-requester-address>
        <app-requester-information [requesterInformationModel]="requesterInformationModel" 
                                     [requestModel]="requestModel"
              (returnRequesterInformationModel)="getUpdatedRequestInformationModel($event)"
                                     *ngIf="requestModel.RequestId > 0&&requestType!=='P2P'" >
          </app-requester-information>
          <app-request-notification [requestModel]="requestModel" [notificationHeader]="'Interested Parties'" *ngIf="requestModel.RequestId > 0&&requestType==='P2P'">
          </app-request-notification>
        </mat-step>

        <mat-step [stepControl]="addShippingAddressGroup" [editable]="isStepsEditable">
          <ng-template matStepLabel>Add Shipping Address</ng-template>
          <div *ngIf="showAddShippingStep == true">
            <!-- This component is for add shipping details section -->
            <add-shipping-address [requestModel]="requestModel" *ngIf="requestModel.RequestId > 0">
            </add-shipping-address>
            <!-- This component is for request notification section -->
            <app-request-notification [requestModel]="requestModel" *ngIf="requestModel.RequestId > 0 && requestType !== 'P2P'">
            </app-request-notification>
          </div>
        </mat-step>
        <mat-step [stepControl]="addChemicalDetailsGroup" [editable]="isStepsEditable">
          <ng-template matStepLabel>Add Sample Details </ng-template>
          <!-- This component is for add chemical details section -->
          <app-chemical-details [requestModel]="requestModel" *ngIf="requestModel.RequestId > 0">
          </app-chemical-details>
        </mat-step>
        
        <mat-step [stepControl]="verifyAndSubmitGroup" [editable]="isStepsEditable">

          <ng-template matStepLabel>Verify and Submit </ng-template>
          <div *ngIf="verifyAndSubmitStep == true">

            <div class="col-12 resShadow mainDiv">
              <p-accordion>
                <p-accordionTab>
                  <p-header [style]="dataMissingMessageWidth">
                    Requester Details 
                    
                    <span class="span-data-missing" *ngIf="!validateNeedByDate(requesterInformationModel.DeliveryDate) &&requestType =='P2P' && requestModel.RequestStatus !=='Ordered' && requestModel.RequestStatus  !== 'Submitted' && requestModel.RequestStatus  !== 'In Process' && requestModel.RequestStatus  !== 'Shipped'">&nbsp;Requested Delivery date must be in future.</span>
               
                    <span class="span-data-missing" *ngIf="requesterInformationModel.ContactNumber === undefined ||
                     requesterInformationModel.ContactNumber === null ||
                      requesterInformationModel.ContactNumber === ''  || requesterPhoneMissing === true">&nbsp;Requester contact information is required.</span>
                  </p-header>
                  <app-requester-details [requesterInformationModel]="requesterInformationModel" *ngIf="requestModel.RequestId > 0&&requestType!=='P2P'"
                                         [requestModel]="requestModel" >
                  </app-requester-details>
                  <app-p2p-requester-details 
                   [requesterInformationModel]="requesterInformationModel"
                   *ngIf="requestModel.RequestId > 0&&requestType =='P2P'"
                  [requestModel]="requestModel" ></app-p2p-requester-details>
                </p-accordionTab>

                <p-accordionTab>
                  <p-header [style]="dataMissingMessageWidth">
                    Shipping Address Details
                    <span class="span-data-missing" *ngIf="showMissingShippingDetails == true">
                      &nbsp; Data missing.
                      Please verify
                    </span>
                    <span class="span-data-missing" *ngIf="shippingAddressPhoneMissing == true">Shipping address is missing contact phone#</span>
                  </p-header>
                  <!-- Shipping Address Details Component -->
                  <app-shipping-address-details [requestModel]="requestModel" *ngIf="requestModel.RequestId > 0"
                                                (eventToReturnRecipientModel)="getRecipientModel($event)">

                  </app-shipping-address-details>
                </p-accordionTab>
                <p-accordionTab>
                  <p-header [style]="dataMissingMessageWidth">
                    Sample Details
                    <span class="span-data-missing" *ngIf="showMissingSampleDetails">
                      &nbsp; Data missing. Please
                      verify
                    </span>                 
                    <span class="span-data-missing" *ngIf="showMissingQuestionnaireDetails">
                      &nbsp; Questionnaire Missing. Please
                      verify
                      <span *ngIf = "showMissingSampleDetails">, </span>
                    </span>
                    <span class="span-data-missing" *ngIf="sampleCommentMissing">
                      &nbsp; Sample Comments Missing. Please
                      verify,
                       
                    </span>
                    <span class="span-data-missing" *ngIf="showPackedWithMissing">
                      &nbsp; No shipping instructions (Packed With) specified. Please
                      verify
                      <span *ngIf = "showMissingSampleDetails">, </span>
                    </span>
                    <span class="span-data-missing" *ngIf="showPriorityError == true">&nbsp;Materials in request must have the same priority, </span>
                    <span class="span-data-missing" *ngIf="showForecastError == true">&nbsp;Forecasted materials cannot be in the same request as materials not forecasted, </span>

                    <span class="span-data-missing" *ngIf="availableAmountChanged == true && isForecastRequest == true">&nbsp; Amount exceeded </span>
                    <span class="span-data-missing" *ngIf="isAmountExceeded == true && isForecastRequest == false">&nbsp; The product you selected is not available. Place a forecast in FARM or contact an <a (click)="$event.stopPropagation();" style="text-decoration: underline;color:black" href="mailTo:fglgssm@corteva.com"><strong>administrator</strong></a> to confirm inventory availability<span *ngIf = "showMissingSampleDetails">, </span></span>
                    <span class="span-data-missing" *ngIf="isPermitAttachmentNotFound">Permit required and not available</span>
                  </p-header>
                  <!-- Sample Details component -->
                  <app-sample-details [requestModel]="requestModel" *ngIf="requestModel.RequestId > 0">

                  </app-sample-details>
                </p-accordionTab>

                
              </p-accordion>
              <p-messages [(value)]="msgs" key="submitValidationNotification" class="messages"></p-messages>
              <div class="grid " style="margin-top:10px">
                <div class="col-9">
                  <p style="margin-bottom:0.5rem !important;">Order Comments</p>
                  <textarea [disabled]="requestModel.RequestStatus === 'Ordered' || disabledComments" style="min-height:75px;width:100%"
                            id="txtRequestComment" [rows]="3" [cols]="60" [(ngModel)]="commentModel.Comment1" (blur)="saveRequestComment(commentModel)"
                            pInputTextarea autoResize="autoResize" maxlength="2000"></textarea>
                </div>
                <div class="col-2">
                  <button pButton type="button" pButton icon="pi pi-times" *ngIf="requestModel.RequestStatus =='Ordered' || requestModel.RequestStatus  == 'Submitted'" class="float-right cancel-order-button" label="Cancel Order"
                          (click)="confirmCancelOrder()" [disabled]="disabledCancel">
                  </button>
                </div>                
                <div class="col-1"> 
                  <button pButton type="submit" pButton icon="pi pi-save" label="Submit"
                          (click)="submitRequest($event)" style="float:right;"
                          [ngClass]="{'red-color-button' : disableButton(),'p-button-success submit-request-button' : showMissingSampleDetails == false && showMissingShippingDetails == false && showPriorityError == false && showForecastError == false,'submit-button-cursor' : disabledSubmit == true } "
                          [disabled]="disableButton()">
                  </button>


                </div>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <!-- End Stepper Section -->
  </div>
</div>

<p-dialog class="userDialog" header="Information" [draggable]="true"
    [modal]="true" [closeOnEscape]="false" [(visible)]="displayDialog" positionLeft=300 positionTop=40
    [closable]="false">    
    <div>
      Request cannot be cancelled because Alchemist LIMS has already completed the order.
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-check" (click)="Cancel()" label="Ok"></button>
  </p-footer>
</p-dialog>

<p-dialog class="userDialog" header="Country Changed Alert" [draggable]="false"
    [modal]="true" [closeOnEscape]="false" [(visible)]="displayCountryChangedDailog" positionLeft=300 positionTop=40
    [closable]="false">    
    <div>
      Changing the Destination Country will require the ITC process to restart.
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-times" (click)="Cancel()" label="Cancel"></button>
      <button type="button" pButton icon="pi pi-save" (click)="submitRequest($event)" label="Submit"></button>
  </p-footer>
</p-dialog>
<p-dialog class="userDialog" header="Override Inventory" [draggable]="false"
    [modal]="true" [dismissableMask]="true" [closeOnEscape]="false" [(visible)]="displayOverrideInsufficientQuantityDailog" positionLeft=300 positionTop=40
    [closable]="true">    
    <div>
      <span><i class='fa fa-2x fa-question-circle' style="margin-right: 10px;"></i>Do you want to override unavailability of inventory and continue to place an order for this request?</span>
    </div>
    <p-footer>
      <button type="button" style="background-color: red;" pButton icon="pi pi-times" (click)="CancelOverrideInsufficientQuantityDailog()" label="Cancel"></button>
      <button type="button" style="background-color: green;" pButton icon="pi pi-save" (click)="submitRequest($event)" label="Submit"></button>
  </p-footer>
</p-dialog>
<!-- confirmation dialog box to for close button -->
<p-confirmDialog #confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="Yes" (click)="confirmDialog.accept()"></button>
    <button type="button" pButton icon="pi pi-times" label="No" (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>
