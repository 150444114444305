import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AddressService } from '../../services/address-service/address.service'
import { ConfigurationSettings } from '../../configuration-settings';
import { UserLookUpModel } from '../../user-look-up/user-look-up.model';
import { AddressModel } from '../../address/address.model';
import { CountryModel } from '../../country/country.model';
import { RecipientService } from '../../services/recipient-service/recipient.service';
import { RequestService } from '../../services/request-service/request.service';
import { RequestShipmentModel } from '../../shipping-address/add/request-shipment.model';
import { ToastModule } from 'primeng/toast';
import { RequestModel } from '../../request/request/request.model';
import { RequesterInformationModel } from '../../request/requester-information/requester-information.model';
import { RequesterInformationService } from '../../services/requester-information-service/requester-information.service';
import { RequestRecipientModel } from '../../request-recipient/request-recipient.model';
import { RecipientModel } from '../../recipient-profile/recipient-profile/recipient.model';
import { trigger, state, style, animate, transition, keyframes, AnimationEvent } from '@angular/animations';
import { constants } from '../../constants/constants'
import { CountryService } from 'src/app/services/country-service/country.service';
import { RegionModel } from 'src/app/region/region.model';
import { SiteModel } from '../../services/site-service/site.model';
import { SiteService } from '../../services/site-service/site.service';
import { UserService } from '../../services/user-service/user.service';


@Component({
  selector: 'add-requester-address',
  templateUrl: './add-requester-address.component.html',
  styleUrls: ['./add-requester-address.component.css'],
  providers: [AddressService, ConfirmationService, MessageService, RecipientService
    , RequestService, CountryService],

  animations: [
    trigger('selectedAddressAnimation', [
      state('selected', style({
      })),
      transition('initial => selected', animate('1000ms', keyframes([
        style({ backgroundColor: 'white', offset: 0 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(0.1)', offset: 0.5 }),
        style({ backgroundColor: '#0080002e', transform: 'scale(1)', offset: 1 })
      ])))
    ])
  ]
})

export class AddRequesterAddressComponent implements OnInit {

  @Input() shippingOption: string = '';
  @Input() requestModel: RequestModel;
  @Input() requesterInformationModel: RequesterInformationModel;
  @Output() returnRequesterInformationModel = new EventEmitter<RequesterInformationModel>();
  public growlMsgs: ToastModule[] = [];
  public serviceResponse: any;
  public restApiURL: string;
  results: RecipientModel[] = [];
  public userLookUpModelList: UserLookUpModel[] = [];
  public userLookUpModel: UserLookUpModel;
  public addressModel: AddressModel = {
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0
  };
  public addressList: AddressModel[] = [];

  public showAddressDialog: boolean = false;
  public selectedCountry: CountryModel;
  public selectedDeliveryDate: Date | any;
  public selectedSiteName: string | any = 'Self-ship';
  public recipientModel: RecipientModel = { RecipientId: 0 };
  public requestRecipientModel: RequestRecipientModel;
  public page: number = null;
  public emptyShippingAddress: AddressModel = {
    PersonFullName: '',
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0
  };
  public shippingAddress: AddressModel = {
    PersonFullName: '',
    AddressId: 0,
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    PostalCode: '',
    City: '',
    State: '',
    CountryId: 0
  };
  public region: RegionModel;
  public showCarousel = true;
  public siteList: Array<SelectItem> = [];

  public AddressSelectionState: string = 'initial'; /// To track if the new address is selected
  showRecipientDialog: boolean = false;

  constructor(private recipientService: RecipientService, protected messageService: MessageService,
    protected confirmationService: ConfirmationService, private addressService: AddressService,
    private requestService: RequestService, protected countryService: CountryService,
    protected siteService: SiteService, protected requesterInformationService: RequesterInformationService,
    protected userService: UserService
  ) {
    this.messageService.clear('addressNotification');
    this.restApiURL = ConfigurationSettings.REST_API_URL + "/azure-ad/users/search?searchString=";
  }

  ngOnInit() {
    this.shippingOption = !this.requesterInformationModel.IsExternalAddress ? "Internal" : "External";
    this.getSites();
    this.PopulateContactNumber();
  }

  public getUserLookupModel() {
    this.userService.getUser(this.requesterInformationModel.Email)
      .subscribe(response => {
  //    this.messageService.add({ severity: 'success', summary: 'Retrieved requester', detail: 'Retrieved requester' });
        this.userLookUpModel = response;
        this.validateSelectedRecipient();
      },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      });
  }
  public getSites() {
    this.siteService.getSitesList()
      .subscribe(response => {
  //    this.messageService.add({ severity: 'success', summary: 'Retrieved sites', detail: 'Retrieved sites' });
      const fxs = response.map(x => ({label: x.SiteName, value: x.SiteName}));
      fxs.forEach(x => this.siteList.push(x));
    },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      });

  }

  public handleTransitionDone(event: AnimationEvent): void {
    if (this.AddressSelectionState !== "initial") {
      this.AddressSelectionState = "initial";
    }
  }

  //---------------------------------------------------------------
  // This method will returns
  //  1. Recipient for the request
  //  2. Shipping address of the request
  //  3. All the available addresses of the recipient
  //---------------------------------------------------------------
  public getRequestRecipient(requestNumber: string) {
    this.requestService.getRequestShippingDetail(this.shippingOption, requestNumber).subscribe(response => this.serviceResponse = response,
      error => {
        this.showCarousel = true;
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.showCarousel = true;
        this.requestRecipientModel = this.serviceResponse;
        if (this.requestRecipientModel.Recipient.RecipientId > 0) {
          this.recipientModel = this.requestRecipientModel.Recipient;
          this.userLookUpModel = this.setRecipientObject(this.recipientModel);
          if (!this.recipientModel.IsActive && this.requestModel.RequestStatus == 'Draft') {
            this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "Selected Recipient profile has been disabled. Please select another recipient", detail: '' });
          }
          this.addressList = [];
          this.getAddressList(this.requestRecipientModel.RecipientAddressList);
          this.shippingAddress = this.requestRecipientModel.ShipmentAddressModel;
          this.shippingAddress.PersonFullName = this.userLookUpModel.DisplayName;
          var requestStatus = this.requestModel.RequestStatus;
          // Show message when status is in Draft.
          if (this.shippingAddress.IsParentUpdated && this.requestModel.RequestStatus == 'Draft') {
            this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "The selected address has been modified. Please select the correct address", detail: '' });
          }

          if (this.shippingAddress.IsParentDeleted && this.requestModel.RequestStatus == 'Draft') {
            this.messageService.add({ key: 'addressNotification', severity: 'error', summary: "The selected address has been deleted. Please select another address", detail: '' });
          }
        }
      })
  }

  //---------------------------------------------------------------------
  // To set the properties of UserLookModel and return the object.
  //---------------------------------------------------------------------
  protected setRecipientObject(recipientModel: RecipientModel): UserLookUpModel {
    var recipient = {
      FirstName: recipientModel.FirstName === null ? '' : recipientModel.FirstName,
      LastName: recipientModel.LastName === null ? '' : recipientModel.LastName,
      DisplayName: recipientModel.FullName === undefined ? ((recipientModel.FirstName === null || recipientModel.LastName === null) ? '' : (recipientModel.FirstName + ' ' + recipientModel.LastName)) : recipientModel.FullName,
      Email: recipientModel.Email,

      LegalName: recipientModel.LegalName,
      InternalContactName: recipientModel.InternalContactName,
      ContactNumber: recipientModel.ContactNumber,

    }
    return recipient;
  }

  //---------------------------------------------------------------------
  // Method to push the addresses to AddressList and return the list.
  //---------------------------------------------------------------------
  private getAddressList(addresses: AddressModel[]) {
    this.addressList = [];
    addresses.forEach((value) => {
      var addressModel = JSON.parse(JSON.stringify(value));
      addressModel.PersonFullName = this.userLookUpModel.DisplayName;
      this.addressList.push(addressModel);
    });
  }

//-------------------------------------
  // Fire when user clicks on save button
  //---------------------------------------
  saveRequesterDetails(shippingOption: string, selectedSite: string) {
    this.requesterInformationModel.IsExternalAddress = shippingOption == "External";
//    this.requesterInformationModel.SiteName = this.selectedSiteName;
//    this.requesterInformationModel.DeliveryDate = this.selectedDeliveryDate;
    this.updateRequesterInformation(this.requesterInformationModel);
  }

  //----------------------------------------------
  // Update requester information
  //----------------------------------------------
  private updateRequesterInformation(requesterInformation) {
    this.requesterInformationService.updateRequesterInformation(requesterInformation).subscribe(res =>
       this.serviceResponse = res,
      error => {
        console.log(error);
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
     // this.messageService.add({ severity: 'success', summary: '', detail: 'Requester details saved successfully' });
      this.returnRequesterInformationModel.emit(JSON.parse(JSON.stringify(requesterInformation)));
      });
  }


  //--------------------------------------------------------------
  // Get the logged in User Contact Number.
  //--------------------------------------------------------------
  private PopulateContactNumber(){
    let loggedInUserEmail = localStorage["loggedInUsername"];
    let requesterEmail = this.requesterInformationModel.Email;
    let type = "internal";
    this.recipientService.getRecipients(type,loggedInUserEmail).subscribe(response => {
        this.results = response.sort((a, b) => b.RecipientId - a.RecipientId);
        let index = 0;
        while (index < this.results.length) {
          if(this.results[index].Email == requesterEmail && this.results[index].ContactNumber != null)
          {
            this.requesterInformationModel.ContactNumber = this.results[index].ContactNumber;
            this.requestModel.RequesterInformationModel.ContactNumber = this.requesterInformationModel.ContactNumber;
            this.updateRequesterInformation(this.requesterInformationModel);
            break;
          }
          index++;
        }
    });
}
  public validateSelectedRecipient() {
    this.recipientService.getRecipient(this.userLookUpModel.Email, this.shippingOption)
    .subscribe(
      res =>  {
        this.serviceResponse = res;
        this.userLookUpModel = this.setRecipientObject(res);
      },
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        if (this.userLookUpModel.Email != this.recipientModel.Email) {
          this.recipientModel = this.serviceResponse;
          if (this.recipientModel.RecipientId == 0) {
            this.messageService.add({ severity: 'error', summary: "Selected requester's recipient profile does not exist. Please click Add", detail: '' });

          }
          else {
            if (!this.recipientModel.IsActive && this.recipientModel.RecipientId != 0 && this.requestModel.RequestStatus == 'Draft') {
              this.messageService.add({ severity: 'error', summary: "Selected requester's recipient profile has been disabled. Please select another requester", detail: '' });
              this.addressList = [];
            }
            else {
              this.messageService.clear('addressNotification');
              this.getRequestShippingDetailForRecipient();
            }
          }
        }
      })
  }

  public getRegionForCountry(countryId: number): Promise<any> {
    return this.countryService.getCountryListAsync().then(res => {
      this.serviceResponse = res
      this.region = this.serviceResponse.filter(c => c.CountryId == countryId);
    })
      .catch(error => {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
      });

  }
  //------------------------------------------
  // To save selected recipient
  //-------------------------------------------
  public getRequestShippingDetailForRecipient() {

    var newRequester= false;
    this.requestService.getRequestShippingDetailForRecipient(
      this.shippingOption, this.requestModel.RequestNumber, this.recipientModel.Email)
      .subscribe(response => this.serviceResponse = response,
      error => {
        this.messageService.add({ severity: 'error', summary: '', detail: error.error });
      },
      () => {
        this.requestRecipientModel = this.serviceResponse;
        if (this.requesterInformationModel.Email != this.recipientModel.Email) {
          newRequester = true;
          this.requesterInformationModel.Email = this.recipientModel.Email;
          this.requesterInformationModel.DisplayName = this.recipientModel.DisplayName;
          this.requesterInformationModel.ContactNumber = this.recipientModel.ContactNumber;
          this.requesterInformationModel.RequesterAddress = null;
          this.requesterInformationModel.RequesterAddressId = null;
          this.shippingAddress = this.emptyShippingAddress;
        }
        else {
          if (this.requesterInformationModel.RequesterAddress !== null && this.requesterInformationModel.RequesterAddress !== undefined) {
            this.requesterInformationModel.RequesterAddress.PersonFullName = this.userLookUpModel.DisplayName;
            this.shippingAddress = this.requesterInformationModel.RequesterAddress;
          } else {
            this.shippingAddress = this.emptyShippingAddress;
          }
        }
        this.getAddressList(this.requestRecipientModel.RecipientAddressList);
        if (this.addressList.length > 0) {
          var defaultAddressModel = new AddressModel();
          defaultAddressModel = this.addressList.filter(s => s.IsDefaultAddress)[0];
          if(this.requesterInformationModel.RequesterAddress){
              defaultAddressModel = this.requesterInformationModel.RequesterAddress
          }
          if (defaultAddressModel) {
            // Set default address as requester address for  the request.
            this.setRequestShipment(defaultAddressModel, null);
            this.requesterInformationModel.RequesterAddress = defaultAddressModel;
            this.requesterInformationModel.RequesterAddressId = defaultAddressModel.AddressId;
            this.requesterInformationModel.RequesterAddress.PersonFullName = this.userLookUpModel.DisplayName;
            this.shippingAddress = this.requesterInformationModel.RequesterAddress;
          }
          else {
            if (newRequester) {
              this.requesterInformationModel.RequesterAddress = null;
              this.requesterInformationModel.RequesterAddressId = null;
              this.shippingAddress = this.emptyShippingAddress;
            }
          }
          this.updateRequesterInformation(this.requesterInformationModel);

        }
        else {
          this.messageService.add({ key: 'addressNotification', severity: 'info', summary: "Please add address to continue", detail: '' });
        }
      })

  }

  //-----------------------------------------------------------------------
  // To set the selected address as shipping address for selected request
  //-----------------------------------------------------------------------
  public async setRequestShipment(address: AddressModel, addressCarousel) {
    this.requesterInformationModel.RequesterAddress = address;
    this.requesterInformationModel.RequesterAddressId = address.AddressId;
    this.requesterInformationModel.IsExternalAddress = this.shippingOption === "External";
    this.shippingAddress = address;
    this.updateRequesterInformation(this.requesterInformationModel);

    if (addressCarousel) {
      addressCarousel.onDotClick(MouseEvent, 0);
    }
  }

  //--------------------------------
  // Open address dialog pop up
  //--------------------------------
  public showAddAddressDialog(assignValueFromRecipient: boolean) {
    this.addressModel.RecipientId = this.recipientModel.RecipientId;
    this.addressModel.AddressId = 0;

    if (assignValueFromRecipient && this.requestRecipientModel.RecipientAddressList.length > 0) {
      this.addressModel.CountryId = this.requestRecipientModel.RecipientAddressList[0].CountryId;
      this.addressModel.Country = this.requestRecipientModel.RecipientAddressList[0].Country;
      this.addressModel.Mobile = this.recipientModel.ContactNumber;
    }
    else if (assignValueFromRecipient) {
      this.addressModel.Mobile = this.recipientModel.ContactNumber;
    }
    else {
      this.addressModel.CountryId = 0;
      this.addressModel.Country = "";
      this.addressModel.Mobile = "";
    }

    this.showAddressDialog = true;
  }

  //--------------------------------------------------------
  // This method will receive the newly created address
  // and set to the array list
  //-------------------------------------------------------
  public receiveNewCreatedAddress(addressModel: AddressModel, assignedCountryToRecipient: boolean) {
    this.messageService.add({ severity: 'success', summary: 'Address saved successfully', detail: '', life: 500 });
    this.showAddressDialog = false;
    this.showCarousel = false;
    addressModel.PersonFullName = this.userLookUpModel.DisplayName;
    this.addressList.unshift(addressModel);
    this.messageService.clear('addressNotification');
    // In  case of external recipient country of first address becomes country of the recipient.
    if (assignedCountryToRecipient == true && this.addressList.length == 1) {
      this.requestRecipientModel.RecipientAddressList = this.addressList;
    }
    //this.getRequestRecipient(this.requestModel.RequestNumber)
    this.showCarousel = true;
    this.setRequestShipment(addressModel, null);
    this.getRequestShippingDetailForRecipient();
  }
  //--------------------------------------
  // This method will close address up.
  //--------------------------------------
  public onAddressPopUpClose() {
    this.showAddressDialog = false;
    console.log("close  pop up called");
    //this.getRequestRecipient(this.requestModel.RequestNumber)
    this.getRequestShippingDetailForRecipient();
    this.messageService.clear('addressNotification');
  }

  //--------------------------------
  // Open Add Recipient dialog pop up
  //--------------------------------
  public showAddRecipientDialog() {
    this.showRecipientDialog = true;
  }

  //--------------------------------------
  // This method will close recipient pop up.
  //--------------------------------------
  public onCloseAddRecipientPopUp() {
    this.showRecipientDialog = false;
  }
}
